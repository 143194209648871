import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SLICE_NAME } from "./consts";

import filesItems from "../../config/items/files";

export interface File {
  id: string;
  displayName: string;
  open: boolean | undefined;
  type: string;
  iconLocation: string;
  fileLocation: string;
  goToUrl: string;
  defaultIconPosition: {
      x: number;
      y: number;
  };
  existsInFolder: boolean
}


export interface FileState {
  files: File[];
  folders: {};
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | undefined;
  selectedFile: number | undefined;
}

// Define the initial state
const initialState: FileState = {
  files: [],
  folders: {},
  status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
  error: '',
  selectedFile: undefined
};

export const FilesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    openFile: (state, action) => {
      const fileIndex = state.files.findIndex(file => file.id === action.payload);
      if (fileIndex !== -1) {
        state.files[fileIndex].open = true;
        state.selectedFile = action.payload;
      }
    },
    closeFile: (state, action) => {
      const fileIndex = state.files.findIndex(file => file.id === action.payload);
      if (fileIndex !== -1) {
        state.files[fileIndex].open = false;
      }
    },
    setFiles: (state, action) => {
      state.files = action.payload;
    },
    selectFile: (state, action) => {
      state.selectedFile = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFiles.pending, (state) => {
          state.status = 'loading';
      })
      .addCase(fetchFiles.fulfilled, (state, action) => {
          state.status = 'succeeded';
          // add defaulticonposition to each icon
          state.files = action.payload.files;
          state.folders = action.payload.folders;

      })
      .addCase(fetchFiles.rejected, (state, action) => {
          state.status = 'failed';
          state.error = action.error.message;
      });
  },
});


export const fetchFiles = createAsyncThunk(
  'icons',
  async () => {
    const response = await fetch('/api/v1/icons');
    const data = await response?.json();
    if(data.error) {
      console.error(data.message);
    }
    return data;
  }
);

export const allFiles = (state) => state[SLICE_NAME].files;

export const { openFile, closeFile, setFiles, selectFile, setStatus } = FilesSlice.actions;

export default FilesSlice;

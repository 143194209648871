import "./styles.scss";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BootUpWindow from "@components/BootUpWindow/BootUpWindow";
import copyToClipboard from "@utils/helpers/copyToClipboard";
import BottomButtonsWrapper from "@components/Login/BottomButtons";
import MiscSlice from "@src/containers/Misc/slice";
import ScreenTypes from "@src/types/screens";
import LeaderBoardWidget from "@components/LeaderBoardWidget";

export default function WelcomePage() {
    const dispatch = useDispatch();
    const [vh, setVh] = useState(window.innerHeight / 100);
    const { setScreen } = MiscSlice.actions;

    const handleSubmitEnter = () => {
        dispatch(setScreen(ScreenTypes.desktop));
    }
  useEffect(() => {
    const handleResize = () => setVh(window.innerHeight / 100);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    return (
        <div className="welcome-page" style={{ height: `calc(${vh}px * 100)` }}>
            <BootUpWindow />
            <div className="welcome-page-col">
            {/* <LeaderBoardWidget pairs='armlqmsqpqx7kvf19tp6kijhtzu6c5tkh5x564dhmegq,
    62xjsenaweksqt57htpb67m1gphrssp5gqfvabjxibph,
    3zaaw2gduylrnelxndvqpzchpuc2zduikkwprqh3ohu8,
    hfdjkietbhndohf2jyyeeqwfyscbsmtmlbpzmy4syzwv' title="The battle of the CROMERS" /> */}
            </div>
            <div className="welcome-page-col welcome-page-inner">
              
                <div className="welcome-page-profile">
                    <div className="profile-pic"></div>
                </div>
                <button
                    className="welcome-page-enter-button"
                    onClick={handleSubmitEnter}>
                    <span>ENTER</span>
                </button>
                <div className="welcome-page-extra-info">
                    <div className="welcome-page-email">
                        <p className="welcome-page-email-text">marketing@corruptlaptop.com</p>
                    </div>
                    <div className="welcome-page-ca">
                        <span>CA:</span>
                        <p className="welcome-page-ca-text">
                            HLwEJQVzs7SvjXuXpBTRHaLp5S6uWoWTUhLjJxBfy1c7
                        </p>
                        <button
                            className="copy-button"
                            onClick={(e) => {
                                e.preventDefault();
                                copyToClipboard(
                                    "HLwEJQVzs7SvjXuXpBTRHaLp5S6uWoWTUhLjJxBfy1c7",
                                    e
                                )
                            }
                            }
                        >
                            <i className="fa fa-clone"></i>
                        </button>
                    </div>
                </div>
                <BottomButtonsWrapper />
            </div>
            <div className="welcome-page-col">
            <LeaderBoardWidget pairs='6uybx1x8yucfj8ystpyizbyg7uqzaq2s46zwphumkjg5,
    5o9kgvozarynwfbytzd1wdrkpkkdr6ldpqbuuqm57nfj,
    5pummeya8hokqmqetulqxxl57vdwt3vhpck5w29szcnw,
    hfdjkietbhndohf2jyyeeqwfyscbsmtmlbpzmy4syzwv' title="2024 Solana Simulated Election" showSponsor={true} />
            </div>
        </div>
    );
}
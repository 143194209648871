import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSpring, animated } from 'react-spring';
import './leaderboardwidget.css';

const { NODE_ENV } = process.env;

// Define a separate component for the pair row
const PairRow = ({ pair, previousFdv, index, className }) => {
    const { baseToken, pairAddress, fdv, info, priceChange, url } = pair;
    const { name, symbol } = baseToken;
    const { imageUrl } = info;
    const { h24 } = priceChange;
    const [flash, setFlash] = useState(false);

    // Use spring to animate changes in fdv
    // const props = useSpring({
    //     number: fdv,
    //     from: { number: previousFdv },
    //     config: { duration: 20000 }
    // });

    const props = useSpring({
        from: { background: 'rgba(240, 240, 240, 0)' },
        to: { background: fdv !== previousFdv ? 'rgba(240, 240, 240, 1)' : 'rgba(240, 240, 240, 0)' },
        reset: fdv !== previousFdv,
        onRest: () => setFlash(false),
        config: { duration: 500 } // Adjust the duration as needed
    });

    useEffect(() => {
        if (fdv !== previousFdv) {
            setFlash(true);
            setTimeout(() => setFlash(false), 500); // Reset the flash effect after the transition duration
        }
    }, [fdv, previousFdv]);

    const formatCurrency = (value) => {
        if (value < 1000) {
            return '$' + Math.round(value);
        } else if (value < 100000) {
            return '$' + Math.round(value / 1000 * 10) / 10 + 'k';
        } else if (value < 1000000) {
            return '$' + Math.round(value / 1000) + 'k';
        } else {
            return '$' + (value / 1000000).toFixed(1) + 'M';
        }
    };

    // if the price change is negative, add a class to style it red
    const priceChangeClass = h24 < 0 ? 'price-change-negative' : 'price-change-positive';

    const trophyClass = index === 0 ? 'trophy-gold' : index === 1 ? 'trophy-silver' : index === 2 ? 'trophy-bronze' : 'trophy-standard';

    // Combine the flash-effect class with the existing mcap class conditionally
    const mcapClass = `mcap ${flash ? 'flash-effect' : ''}`;

    const handleClick = (url) => {
        window.location.href = url;
    }

    // Render the pair row
    return (
        <div key={pairAddress} className={`leaderboard-row flex flex-row items-center pair-${pairAddress} ${className}`} data-pair-address={pairAddress}>
            <div className={`leaderboard-token-item flex flex-auto px-2`} onClick={() => { handleClick(url)} }>
                {/* <span className={`leaderboard-token-rank trophy ${trophyClass} flex-initial`}></span> */}

                <span className="image"><img src={imageUrl} alt={name} /></span>

                <span className='details-box'>
                    <div className={mcapClass}>
                        {formatCurrency(fdv)}
                    </div>
                    <span className="name-box">
                        <span className="name">{name}</span>
                        <span className="symbol">${symbol}</span>
                        <span className={`price-change ${priceChangeClass}`}><span className='label'>24H:</span> <span>{h24}% </span></span>
                        <span className="chart"><a href={url} target="_blank">Chart</a></span>
                    </span>
                </span>
            </div>
        </div>
    );
};

// Main component for the leaderboard widget
const LeaderBoardWidget = ({pairs, showSponsor = true, showPoweredby = true, title = ''}) => {
    // State for storing pairs data
    const [data, setData] = useState([]);
    const [previousData, setPreviousData] = useState({});
    const [previousSponsorData, setPreviousSponsorData] = useState({});
    const [error, setError] = useState(null);
    const [sponsor, setSponsor] = useState(null);

    // trim the white spaces from the pairs string
    pairs = pairs.replace(/\s/g, '');

    useEffect(() => {
        // Fetch pairs data from the API
        const fetchData = async () => {
            try {
                let url = `https://corruptlaptop.com/api/leaderboard?pairs=`+pairs;
                if (NODE_ENV === 'development') {
                    url = `/api/leaderboard?pairs=`+pairs;
                }

                const response = await axios.get(url);
                const fetchedData = response.data.pairs;
                const sponsor = response.data.sponsor;
                if (!fetchedData || fetchedData.length === 0) {
                    setError('No data available');
                    return;
                }

                console.info(fetchedData);

                // Create an object to keep track of the previous FDVs
                const newPreviousData = fetchedData.reduce((acc, pair) => {
                    acc[pair.pairAddress] = previousData[pair.pairAddress] || pair.fdv;
                    return acc;
                }, {});

                // Create an object to keep track of the previous sponsor FDVs
                const newPreviousSponsorData = sponsor ? {
                    [sponsor.pairAddress]: previousSponsorData[sponsor.pairAddress] || sponsor.fdv
                } : {};

                setPreviousData(newPreviousData); // Update previous FDVs
                setData(fetchedData); // Update with the new data
                setSponsor(sponsor);
                console.log(sponsor);
                setPreviousSponsorData(newPreviousSponsorData);
            } catch (err) {
                setError(err.message || 'Error fetching data');
            }
        };

        fetchData(); // Fetch data immediately on mount
        const interval = setInterval(fetchData, 5000); // Fetch data every 30 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, []);

    // Render the leaderboard widget
    return (
        <div id="crypto-leaderboard">
           {title && <div className="leaderboard-header">
                    {title}
            </div>}
            {error ? (
                <div className='error'>Error: {error}</div>
            ) : !data.length ? (
                <div className='loading'>Loading...</div>
            ) : data.length === 0 ? (
                <div className='no-data'>No data available</div>
            ) : (
                data.map((pair, index) => (
                    <PairRow
                        key={pair.pairAddress}
                        className={'standard'}
                        pair={pair}
                        index={index}
                        previousFdv={previousData[pair.pairAddress]}
                    />
                ))
            )}
            {showPoweredby && <div className="poweredby">Powered by $LAPTOP</div>}
            {sponsor && showSponsor && <PairRow
                key={sponsor?.pairAddress}
                pair={sponsor}
                className={'sponsor'}
                index={-1}
                previousFdv={previousSponsorData[sponsor?.pairAddress]} />
            }
        </div>

    );
};

export default LeaderBoardWidget;

import React, { useState, useEffect, useMemo } from "react";
import { Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import {getPdfAsBase64} from "@utils/helpers/getPdfAsBase64";
import styled from "styled-components";
import './styles.scss';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const FileContentPdfWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;

  .pdf-footer {
    bottom: 0;
    background-color: #e0e0e0;
    /* top: 0; */
    width: 100%;
    padding: 5px;
  }
`;

function FileContentPdf({ fileLocation, pageCount = 1 }) {
  const [base64Pdf, setBase64Pdf] = useState("");
  const [numPages, setNumPages] = useState(null);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    getPdfAsBase64(fileLocation).then((base64) => {
      if (base64) {
        setBase64Pdf(base64);
      }
    });
  }, [fileLocation]);

  const file = useMemo(() => {
    if (base64Pdf.includes('base64,')) {
      return { data: atob(base64Pdf.split('base64,')[1]) };
    } else {
      return null;
    }
  }, [base64Pdf]);
  return (
    <FileContentPdfWrapper>
      {base64Pdf && file ? (
        <Viewer
        fileUrl={`${base64Pdf}`}
        plugins={[
          defaultLayoutPluginInstance,
        ]}
      />
      ) : (
        <p>Loading...</p>
      )}
    </FileContentPdfWrapper>
  );
}

export default FileContentPdf;
import React, { useCallback, useEffect, useRef, useState } from "react";
import LightGallery from 'lightgallery/react';
import { LightGallery as ILightGallery } from 'lightgallery/lightgallery';
import isMobile from "@utils/helpers/isMobile";
import styled from "styled-components";
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import {getPdfAsBase64} from "@utils/helpers/getPdfAsBase64";
import { BsPlayBtn } from "react-icons/bs";
import './styles.scss';

interface File {
  id: string;
  displayName: string;
  open: boolean;
  defaultIconPosition: string;
  iconLocation: string;
  fileLocation: string;
  type: string;
  goToUrl: string;
}

interface GalleryProps {
  files: File[];
}

const FolderContentScrollView = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 15px;
`;

const Gallery: React.FC<GalleryProps> = ({ files }) => {
  const lightGallery = useRef<ILightGallery>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [galleryContainer, setGalleryContainer] = useState(null);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setGalleryContainer(containerRef.current);
    }
  }, []);
  

  return (
    <FolderContentScrollView ref={containerRef} className="folder-scroll-view">
      <LightGallery
        elementClassNames="gallery"
        container={galleryContainer}
        addClass="gallery-wrapper"
        speed={500}
        plugins={[lgZoom, lgFullscreen, lgVideo, lgThumbnail]}
        onInit={onInit}
        enableDrag={isMobile()}
        mode="lg-fade"
        mobileSettings={{ controls: true }}
      >
        {files.map((file) => {
          let {
            id,
            displayName,
            iconLocation,
            fileLocation,
            type,
          } = file;

          let video;
          let html;
          if (type === 'video') {
            video = {
              source: [{
                src: fileLocation,
                type: 'video/mp4',
              }],
              attributes: { preload: false, controls: true },
            };
            html  = `<video class="lg-video-object lg-html5" controls preload="none">
                    <source src="${fileLocation}" type="video/mp4">
                    Your browser does not support HTML5 video.
                </video>`;
          }

          const [fileSrc, setFileSrc] = useState(fileLocation);

          useEffect(() => {
            if (type === 'pdf') {
              getPdfAsBase64(fileLocation).then((base64) => {
                if (base64) {
                  setFileSrc(base64+'#navpanes=0');
                }
              });
            }
          }, [fileLocation, type]);

          let iframeAttributes = {};
          if (type === 'pdf') {
            iframeAttributes = { 'data-iframe': true };
          }
          return (
            <a
                key={`file-${id}`}
                className={'gallery-item lg-image ' + (type === 'video' ? 'lg-video' : '')}
                href={fileSrc}
                data-pinterest-text={displayName}
                data-tweet-text={displayName}
                data-src={type === 'video' ? '' : fileSrc}
                data-sub-html={`<h4>${displayName}</h4>`}
                data-video={type === 'video' ? JSON.stringify(video) : undefined}
                data-poster={type === 'video' ? iconLocation : undefined}
                data-html={type === 'video' ? html : ''}
                {...iframeAttributes}
            >
                {type === 'video' ? <BsPlayBtn className="gallery-play-button" /> : null}
                <img alt={displayName} src={iconLocation} />
                <div className="gallery-title">
                    {displayName}
                </div>
            </a>
          );
        })}
      </LightGallery>
    </FolderContentScrollView>
  );
}

export default Gallery;
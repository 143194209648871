import styled from "styled-components";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { TbCurrencySolana } from "react-icons/tb";

const BottomButtonsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5%;
`;
const BottomButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  width: 6rem;
  opacity: 0.85;
  cursor: pointer;
  @media (max-width: 550px) {
    width: 5rem;
  }
`;

const IconWrapper = styled.div`
  height: 2.8rem;
  width: 2.8rem;
  background-color: #270207;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
  font-size: 22px;
`;

const IconLabel = styled.p`
  color: white;
  margin-top: 12px;
  font-size: 14px;
  opacity: 0.75;
  font-weight: 600;
`;

export default function BottomButtons() {
  return (
    <BottomButtonsWrapper className="bottom-buttons">
      <BottomButton
        onClick={() => {
          window.open("https://x.com/degenlaptop", "_blank");
        }}
      >
        <IconWrapper>
          <FaXTwitter />
        </IconWrapper>
        <IconLabel>Twitter</IconLabel>
      </BottomButton>
      <BottomButton
        onClick={() => {
          window.open(
            "https://jupiter-terminal.dexscreener.com/?inputMint=HLwEJQVzs7SvjXuXpBTRHaLp5S6uWoWTUhLjJxBfy1c7&outputMint=So11111111111111111111111111111111111111112",
            "_blank"
          );
        }}
      >
        <IconWrapper>
          <TbCurrencySolana />
        </IconWrapper>
        <IconLabel>Buy</IconLabel>
      </BottomButton>
      <BottomButton
        onClick={() => {
          window.open("https://t.me/HBcorruptlaptop", "_blank");
        }}
      >
        <IconWrapper>
          <FaTelegramPlane />
        </IconWrapper>
        <IconLabel>Telegram</IconLabel>
      </BottomButton>
      <BottomButton
        onClick={() => {
          window.open("https://dexscreener.com/solana/hfdjkietbhndohf2jyyeeqwfyscbsmtmlbpzmy4syzwv", "_blank");
        }}
      >
        <IconWrapper>
        <svg width="1.2em" height="1.2em" viewBox="0 0 112 133" fill="none" xmlns="http://www.w3.org/2000/svg">
          
            <path d="M67.4747 47.3773C71.5533 45.3486 76.7431 42.3623 81.9391 38.2708C83.0347 40.5402 83.1547 42.5205 82.5902 44.1196C82.1902 45.2466 81.4364 46.2268 80.4453 47.0066C79.372 47.8494 78.0316 48.463 76.5498 48.7946C73.7382 49.4263 70.4556 49.0575 67.4747 47.3773ZM68.1818 68.1634L73.5809 71.2743C62.5569 77.4375 59.56 88.8817 56 100.028C52.4404 88.8817 49.4431 77.4375 38.4196 71.2743L43.8187 68.1634C44.341 67.9665 44.7874 67.6098 45.094 67.1443C45.4005 66.6788 45.5515 66.1284 45.5253 65.5721C45.0307 55.1329 47.8564 50.5165 51.668 47.618C53.0351 46.5797 54.5276 46.0583 56 46.0583C57.4724 46.0583 58.9649 46.5797 60.3324 47.618C64.144 50.5165 66.9698 55.1329 66.4751 65.5721C66.4489 66.1284 66.5999 66.6788 66.9065 67.1443C67.2131 67.6098 67.6595 67.9665 68.1818 68.1634ZM56 0C62.2409 0.167137 68.4973 1.37566 73.9271 3.72666C77.6871 5.3568 81.1956 7.51007 84.3725 10.0849C85.8071 11.2474 86.9884 12.3703 88.2898 13.6631C91.7996 13.7841 96.9289 9.89476 99.3102 6.25543C95.212 19.6574 76.5107 35.484 63.5636 41.5399C63.5582 41.5377 63.5547 41.5346 63.5507 41.5319C61.2271 39.7621 58.6138 38.8772 56 38.8772C53.3862 38.8772 50.7733 39.7621 48.4498 41.5319C48.4458 41.5341 48.4422 41.5381 48.4369 41.5399C35.4893 35.484 16.7884 19.6574 12.6902 6.25543C15.0711 9.89476 20.2004 13.7841 23.7102 13.6631C25.012 12.3708 26.1933 11.2474 27.6276 10.0849C30.8044 7.51007 34.3129 5.3568 38.0729 3.72666C43.5031 1.37566 49.7596 0.167137 56 0ZM44.5253 47.3773C40.4471 45.3486 35.2569 42.3623 30.0613 38.2708C28.9658 40.5402 28.8458 42.5205 29.4098 44.1196C29.8102 45.2466 30.564 46.2268 31.5547 47.0066C32.6284 47.8494 33.9689 48.463 35.4507 48.7946C38.2622 49.4263 41.5444 49.0575 44.5253 47.3773Z" fill="white"/>
            <path d="M87.6298 33.2571C90.4902 30.3776 93.0107 27.1905 95.0373 24.3465L96.0667 26.279C99.3804 32.8922 101.102 39.4788 101.102 46.8914L101.095 58.6539L101.157 64.7515C101.397 79.7211 104.644 94.8667 112 108.727L96.6102 96.3483L85.7209 113.975L74.2809 103.232L56 132.824L37.7191 103.233L26.2796 113.975L15.3902 96.3487L0 108.728C7.35556 94.8671 10.6027 79.7215 10.8431 64.7519L10.9053 58.6543L10.8982 46.8918C10.8982 39.4788 12.6196 32.8922 15.9342 26.2795L16.9631 24.347C18.9898 27.191 21.5098 30.3776 24.3707 33.2575L23.4773 35.1085C21.7418 38.7034 21.1671 42.7231 22.5191 46.546C23.3907 49.0083 24.9813 51.1199 27.032 52.7318C29.0231 54.2972 31.3796 55.3524 33.8467 55.9066C35.4538 56.2674 37.0911 56.4159 38.7178 56.3654C38.3382 58.5116 38.1724 60.7376 38.1618 63.0056L23.6444 71.3696L34.8471 77.6334C35.7425 78.1342 36.5949 78.7079 37.3956 79.3487C46.6316 87.6204 52.1844 112.091 56.0004 124.044C59.8169 112.091 65.3693 87.6204 74.6058 79.3487C75.4064 78.7078 76.2588 78.1341 77.1542 77.6334L88.3569 71.3696L73.8391 63.0056C73.8284 60.7376 73.6627 58.5116 73.2831 56.3654C74.9098 56.4159 76.5471 56.2674 78.1542 55.9066C80.6213 55.3524 82.9782 54.2972 84.9689 52.7318C87.0191 51.1199 88.6102 49.0083 89.4813 46.546C90.8338 42.7231 90.2587 38.7039 88.5236 35.1085L87.6302 33.2575L87.6298 33.2571Z" fill="white"/>
    
        </svg>

        </IconWrapper>
        <IconLabel>Dexscreener</IconLabel>
      </BottomButton>
    </BottomButtonsWrapper>
  );
}

import "./styles.scss";
import React, {useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import ChromeBar from "../../components/ChromeBar";
import Dock from "../../components/Dock";
import { ITEMS_CONFIG } from "../../config/items";
import Item from "../../components/Item";
import { ItemTypes } from "../../types/items";

import useWindowSize from "../../utils/helpers/hooks/useWindowDimensions";
import MacOSWarningDialog from "../../components/WarningDialog";
import Chat from "../../components/Chat";

//folders
import Folder from "../../components/Folder";
import { updateFolders } from "../../containers/Folders/slice";
import { SLICE_NAME as FOLDER_SLICE_NAME } from "../../containers/Folders/consts";

//files
import File from "../../components/File";
import { fetchFiles, setStatus, setFiles } from "../../containers/Files/slice";
import { SLICE_NAME as FILE_SLICE_NAME } from "../../containers/Files/consts";

// LeaderboardWidget
import LeaderBoardWidget from "@components/LeaderBoardWidget";
import isMobile from "@utils/helpers/isMobile";


//apps
import { allAppsSelector } from "../../containers/Apps/selectors";

function DesktopPage() {
  const windowSize = useWindowSize();
  const dispatch = useDispatch();
 
  const allFiles = useSelector((state) => state[FILE_SLICE_NAME].files);
  const sliceFolders = useSelector((state) => state[FILE_SLICE_NAME].folders);
  const allFolders = useSelector((state) => state[FOLDER_SLICE_NAME].folders);
  const status = useSelector((state) => state[FILE_SLICE_NAME].status);
  const error = useSelector((state) => state[FILE_SLICE_NAME].error);

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchFiles() as any)
      .then(()=>{
        dispatch(setStatus('succeeded'));
      })
      .catch(() => {
        // Handle the error here, for example by setting the status to 'error'
        dispatch(setStatus('error'));
      });
    }

    if (status === 'succeeded') {
      dispatch(updateFolders(sliceFolders));
    }
  }, [status, dispatch, sliceFolders]);

  const allApps = useSelector(allAppsSelector);
  const [isChromeBarExpanded, setIsChromeBarExpanded] = React.useState(false);

  return (
    <div className="page" id="page">
      <ChromeBar isChromeBarExpanded={isChromeBarExpanded}
        setIsChromeBarExpanded={setIsChromeBarExpanded} />

      {windowSize.width < 650 && (
        <MacOSWarningDialog message="This site is optimized for desktop use. For the best experience, please access it through a desktop device." />
      )}

      {allFolders && Object.values(allFolders)?.map((folder: any) => {
        const {
          id,
          displayName,
          open,
          defaultIconPosition,
          defaultIconPositionMobile,
          iconLocation,
          folderChildren,
        } = folder;

        return (
          <Folder
            key={`folder-${id}`}
            id={id}
            displayName={displayName}
            open={open}
            position={defaultIconPosition}
            positionMobile={defaultIconPositionMobile}
            iconLocation={iconLocation}
            folderChildren={folderChildren}
          ></Folder>
        );
      })}

      {allFiles && Object.values(allFiles)?.map((file: any) => {
        const {
          id,
          displayName,
          open,
          defaultIconPosition,
          defaultIconPositionMobile,
          iconLocation,
          fileLocation,
          type,
          goToUrl,
          existsInFolder
        } = file;

        const isLink = !!goToUrl;

        return (
          <File
            key={`file-${id}`}
            id={id}
            displayName={displayName}
            open={open}
            position={defaultIconPosition}
            positionMobile={defaultIconPositionMobile}
            iconLocation={iconLocation}
            isLink={isLink}
            goToUrl={goToUrl}
            type={type}
            fileLocation={fileLocation}
            fileParent={null}
            existsInFolder={existsInFolder}
            isParentFolder={false}
          ></File>
        );
      })}

      {allApps["whatsapp"]?.open && <Chat />}

      {!isMobile() && <LeaderBoardWidget pairs='6uybx1x8yucfj8ystpyizbyg7uqzaq2s46zwphumkjg5,
    5o9kgvozarynwfbytzd1wdrkpkkdr6ldpqbuuqm57nfj,
    5pummeya8hokqmqetulqxxl57vdwt3vhpck5w29szcnw,
    hfdjkietbhndohf2jyyeeqwfyscbsmtmlbpzmy4syzwv' title="2024 Solana Simulated Election" showSponsor={true} />}

      <Dock allApps={allApps} allFiles={allFiles}/>
    </div>
  );
}

export default DesktopPage;
